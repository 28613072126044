import AltHeading from '../components/alt-heading.component';
import GalleryBuilder from '../components/gallery-builder.component';

import '../styles/pages/portfolio.styles.scss';
import images from '../assets/portfolio.json';

const Portfolio = () => (
    <div className="portfolio">
        <AltHeading
            title="portfolio"
            subtitle="To bring you or your brand to digital life, I utilize a background in illustration and cartoon design to create graphics and websites."
            text={["Quirky, playful design is my favorite! I use this to my advantage to create designs bursting with personality unique to who it’s made for. Check out some of my work and see for yourself. ;)",
            "#designwithpersonality"
             ]}
        />

        <GalleryBuilder images={images} />
    </div>
);

export default Portfolio;