import { useState, useEffect } from 'react';
import Hero from '../components/hero.component';
import Gallery from '../components/gallery.component';
import ViewportSize from '../components/viewport-size.component';

import '../styles/pages/home.styles.scss';

import bunnyHomepageImage from '../assets/images/bunny_homepage.png';
import bunnyHomepageImageMobile from '../assets/images/bunny_homepage_mobile.png';
//json for gallery
import galleryItems from '../assets/featured-portfolio.json';

export default function Home () {
    const { width } = ViewportSize();
    const [bunnyImage, setBunnyImage] = useState(bunnyHomepageImage);

    //watch mobilebreakpoint for hero image
    useEffect(() => {
        const mobileBreakpoint = 900;
        width <= mobileBreakpoint ? setBunnyImage(bunnyHomepageImageMobile) : setBunnyImage(bunnyHomepageImage);
    }, [width]);

    //use effect
        //load 4 porfolio items, send items to Gallery

    return (
        <div className="home">
            <Hero
                image={bunnyImage}
                isBunnyImage
                imageAlt="bunny saying sup"
                title="victoria Jones;)"
                titleIsLogo
                subtitle="Front-end Web Development & Design"
                text={["I utilize a background in illustration and cartoon design to create personalized designs and web sites!"
                ]}
                button="see my work"
                buttonLink="portfolio"
                heroClass="home__hero"
            />

            <Gallery images={galleryItems} />
        </div>
    );
}