import { useState, useEffect } from 'react';

import '../styles/components/pop-up.styles.scss';

export default function Popup ({ popupContent, clearPopupContent }) {
    const [activeStyles, setActiveStyles] = useState(false);

    useEffect(() => {
        if(popupContent !== undefined) {
            setActiveStyles(true);
        }
    },[popupContent]);

    const handlePopupClose = () => {
        setActiveStyles(false);
        clearPopupContent(undefined);
    }

    const createAltText = () => {
        let altText;
        if(popupContent){
            altText = popupContent.substring(0, popupContent.indexOf('.'));
        }
        return altText;
    }

    return(
        <div className={`popup ${activeStyles ? 'popup--active' : ''}`}>
            <div className={`popup__wrapper ${activeStyles ? 'popup__wrapper--active' : ''}`}>
                <div className="popup__close">
                    <div className={`popup__close--button ${activeStyles ? 'popup__close--button--active' : ''}`}
                        onClick={handlePopupClose}
                    >
                    </div>
                </div>
                <img className="popup__contentFill"
                    src={`/images/portfolio-images/${popupContent}`}
                    alt={createAltText()}
                />
            </div>
        </div>
    );
}