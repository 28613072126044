import CustomButton from './custom-button.component';

import '../styles/components/custom-card.styles.scss';

const CustomCard = ({ title, highlights, image }) => (
    <div className="custom-card">
        <div className="custom-card--wrapper">
            <div className="custom-card__image">
                <img src={image} className="custom-card__image--icon" alt="bunny icon" />
            </div>
            <h3 className="custom-card__title">{ title }</h3>
            <ul className="custom-card__list">
                {
                    highlights.map((highlight) =>
                        <li className={`custom-card__item`}
                            key={`custom-card__item--${highlights.indexOf(highlight)}`}
                        >
                            {highlight}
                        </li>
                    )
                }
            </ul>
            <CustomButton
                buttonClass="custom-card__button"
                linkToPage="portfolio"
            >
                see more
            </CustomButton>
        </div>
    </div>
);

export default CustomCard