import GalleryBuilder from './gallery-builder.component';
import CustomButton from './custom-button.component';
import StylizedHeader from './stylized-header.component';

import '../styles/components/gallery.styles.scss';

export default function Gallery ({ images }) {

    return (

        <div className="gallery section-margin">
            <StylizedHeader
                headerClass="gallery__title"
                hType="2"
            >
                check out some of my work :)
            </StylizedHeader>
            <GalleryBuilder images={images} />
            <CustomButton
                linkToPage="portfolio"
            >
                see more
            </CustomButton>
        </div>
    );

}