import { Link } from 'react-router-dom'

import '../styles/components/footer.styles.scss';

import Logo from './logo.component';
import WebsiteNavigation from './website-navigation.component';

export default function Footer () {
    const links = [
        "https://www.instagram.com/victoria.jones.design/",
        "https://www.linkedin.com/in/victoria-jones-design/",
        "contact"
    ];
    const socialIcons = [
        "instagram",
        "linkedin",
        "email"
    ];

    return (
        <footer className="footer section-margin">
            <div className="footer__logo--wrapper">
                <Link to="/">
                    <Logo logoClass="footer" />
                </Link>
                <span className="footer__logo--text">victoria jones</span>
            </div>
            <WebsiteNavigation
                links={links}
                socialNav
                socialNavIcon={socialIcons}
            />
        </footer>
    );
}