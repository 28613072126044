import { useRef, useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

import IconLinkText from './icon-text-link.component';
import CustomButton from './custom-button.component';
import StylizedHeader from './stylized-header.component';

import '../styles/components/contact-form.styles.scss';
import { ReactComponent as MailIcon } from '../assets/svg/mail.svg';

export default function ContactForm ({ title }) {
    const form = useRef();
    const formName = useRef();
    const formEmail = useRef();
    const formMessage = useRef();
    const recaptchaRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [activeStyles, setActiveStyles] = useState(false);

    //emailjs init
    useEffect(() => {
        emailjs.init(process.env.REACT_APP_EMAILJS_USER);
    },[]);

    const handleSubmission = () => {
        let namePatt = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
        let emailPatt = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        let messagePatt = /^[a-zA-Z0-9\s\-\.\?\!\,\'\"]+$/;

        let nameValid = false;
        let emailValid = false;
        let messageValid = false;

        //name
        try {
            if(!namePatt.test(formName.current.value)) {
                throw "please enter a name with no special characters";
            } else {
                nameValid = true;
                setError(formName.current);
            }
        } catch(msg) {
            nameValid = false;
            setError(formName.current, msg);
        }
        //email
        try {
            if(!emailPatt.test(formEmail.current.value)) {
                throw "please enter a valid email";
            } else {
                 emailValid = true;
                 setError(formEmail.current);
            }
        } catch(msg) {
            emailValid = false;
            setError(formEmail.current, msg);
        }
        //message
        try {
            if(!messagePatt.test(formMessage.current.value)) {
                throw "please enter a message withough special characters outside of basic punctuation."
            } else {
                messageValid = true;
                setError(formMessage.current);
            }
        } catch(msg) {
            messageValid = false;
            setError(formMessage.current, msg);
        }

        if (nameValid && emailValid && messageValid) sendEmail();
    }

    const setError = (subject, msg) => {
        let errordiv = subject.previousSibling;
        if(msg) {
            errordiv.innerHTML = msg;
        } else {
            errordiv.innerHTML = "";
        }
    }

    const sendEmail = async () => {
        const serviceId = 'default_service';
        const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
        const token = recaptchaRef.current.getValue();

        try {
            setLoading(true);
            await emailjs.send(serviceId, templateId, {
                user_name: formName.current.value,
                user_email: formEmail.current.value,
                message: formMessage.current.value,
                'g-recaptcha-response': token
            });
            setEmailSent(true);
        } catch (err) {
            console.log(err);
            setActiveStyles(true);
            setEmailError(true);
        } finally {
            setLoading(false);
            form.current.reset();
            recaptchaRef.current.reset();
            setActiveStyles(true);
        }
    }

    const renderContactFormState = () => {
        if (emailSent) {
            return(
                <div className="contact-form__state__email-success">
                    <h3 className="contact-form__state--header">
                            Email Sent!
                    </h3>
                    <p>
                        please give a couple days for me to get back to you :)
                    </p>
                </div>
            );
        } else if (emailError) {
            return(
                <div className="contact-form__state__email-error">
                    <h3 className="contact-form__state--header">
                        Uh oh! There seems to be something wrong!
                    </h3>
                    <p>
                        please try sending an email again later :(
                    </p>
                </div>
            );
        }
    }

    return (
        <section className="contact-form--wrapper section-margin">

            {
                title ?
                (
                    <StylizedHeader
                        headerClass="contact-form__title"
                        hType="2"
                    >
                        {title}
                    </StylizedHeader>
                )
                :
                null
            }

            <div className="contact-form">
                <div className="contact-form__side contact-form__side--1">
                    <div className="contact-form__header">
                        <h3 className="contact-form__header--title">
                            contact me
                        </h3>
                        <div className="contact-form__header__icon-text--link">
                            <IconLinkText
                                icon="email"
                                text="hello@victoria-jones-design.com"
                                link="#"
                            />
                        </div>
                    </div>

                    <div className={`contact-form__loading ${loading ? 'contact-form__loading--active' : ''}`}>
                        <div className="contact-form__loading--wrapper">
                            <div className="contact-form__loading__marker">
                                <MailIcon className="contact-form__loading__marker--icon" />
                            </div>
                            <h3 className="contact-form__loading--title">
                                sending...
                            </h3>
                        </div>
                    </div>

                    <div className={`contact-form__state ${activeStyles ? 'contact-form__state--active' : ''}`}>
                        {renderContactFormState()}
                    </div>

                    <form ref={form} action="#" className="contact-form__form">
                        <div className="contact-form__group">
                            <div className="contact-form__error" id="contact-form__name--error"></div>
                            <input ref={formName} type="text" className="contact-form__input" id="contact-form__name" placeholder="Name" maxLength="70" required />
                            <div className="contact-form__input-shadow">&nbsp;</div>
                            <label htmlFor="contact-form__name" className="contact-form__label">Name</label>
                        </div>
                        <div className="contact-form__group">
                            <div className="contact-form__error" id="contact-form__email--error"></div>
                            <input ref={formEmail} type="text" className="contact-form__input" id="contact-form__email" placeholder="Email" required />
                            <div className="contact-form__input-shadow">&nbsp;</div>
                            <label htmlFor="contact-form__email" className="contact-form__label">Email</label>
                        </div>
                        <div className="contact-form__group">
                            <div className="contact-form__error" id="contact-form__message--error"></div>
                            <textarea ref={formMessage} name="textarea" id="contact-form__message" className="contact-form__textarea" placeholder="Message" maxLength="1000" required></textarea>
                            <div className="contact-form__input-shadow">&nbsp;</div>
                            <label htmlFor="contact-form__message" className="contact-form__label">Message</label>
                        </div>

                        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            ref={recaptchaRef}
                        />

                        <div className="contact-form__group">
                            <CustomButton
                                handleEvent={handleSubmission}
                            >
                                Send
                            </CustomButton>
                        </div>
                    </form>
                </div>

                <div className="contact-form__side contact-form__side--2">
                    <div className="contact-form__side-title">
                        <span className="contact-form__side-title--title">
                            not a fan of email?
                        </span>
                        <span className="contact-form__side-title--sub-title">
                            no problem.
                        </span>
                    </div>
                    <div className="contact-form__icon-text__links">
                        <div className="contact-form__icon-text--link">
                            <IconLinkText
                                icon="instagram"
                                text="@victoria.jones.design"
                                externalLink="https://www.instagram.com/victoria.jones.design/"
                            />
                        </div>
                        <div className="contact-form__icon-text--link">
                            <IconLinkText
                                icon="linkedin"
                                text="linkedin.com/in/victoria-jones-design/"
                                externalLink="https://www.linkedin.com/in/victoria-jones-design/"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}