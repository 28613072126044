import { Link } from 'react-router-dom';

import '../styles/components/custom-button.styles.scss';

const CustomButton = ({ children, buttonClass, linkToPage, linkToExternal, handleEvent }) => (
    /*<div className={`custom-button
        ${buttonClass ? buttonClass : ''}
    `}>
        {
            linkToPage ?
            (
                <Link
                    className="custom-button__link"
                    to={`/${linkToPage === 'home' ? '' : linkToPage}`}
                >
                    { children }
                </Link>
            )

            :

            children
        }
    </div>*/


        linkToPage ?
        (
            <Link
                className="custom-button__link"
                to={`/${linkToPage === 'home' ? '' : linkToPage}`}
            >
                <div className={`custom-button
                    ${buttonClass ? buttonClass : ''}
                `}>

                { children }

                </div>
            </Link>
        )

        : handleEvent ?

        (
            <div className={`custom-button
                ${buttonClass ? buttonClass : ''}
            `}
                onClick={handleEvent}
            >

            { children }

            </div>
        )

        :

        (
            <div className={`custom-button
                ${buttonClass ? buttonClass : ''}
            `}>

            { children }

            </div>
        )

);

export default CustomButton;