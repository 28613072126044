import '../styles/pages/about.styles.scss';

import Hero from '../components/hero.component';
import Highlights from '../components/highlights.component';
import ContactForm from '../components/contact-form.component';

import bunnyAboutpageImage from '../assets/images/bunny_aboutpage.png';

const About = () => (
    <div className="about">
        <Hero
            image={bunnyAboutpageImage}
            isBunnyImage
            imageAlt="bunny with fingerguns"
            title="about me"
            subtitle="Hello! I’m Victoria, or Tori for short."
            text={["I’m a graphic designer, web designer, front-end web developer, artist, aaaand… well I think you get the idea. To put it simply I’m a front-end web developer and designer that almost always has her nose stuck in a coffee stained sketch book.",
                "My designs focus on unique personality and playful interactions that are created entirely around you or your brand. You won’t find any drab copy-paste designs here!"
            ]}
            button="let's get in touch"
            buttonLink="contact"
            heroClass="about__hero"
        />
        <Highlights />
        <ContactForm title="let's get in touch" />
    </div>
);

export default About;