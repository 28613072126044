import AltHeading from '../components/alt-heading.component';
import ContactForm from '../components/contact-form.component';

import '../styles/pages/contact.styles.scss';

const Contact = () => (
    <div className="contact">
        <AltHeading 
            title="contact"
            subtitle="Don’t be shy, go ahead and send a message!"
            text={["I can be reached by email or social media. ;)"]}   
        /> 
        <ContactForm />
    </div>
);

export default Contact;