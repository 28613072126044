import CustomCard from './custom-card.component';
import StylizedHeader from './stylized-header.component';

import '../styles/components/highlights.styles.scss';

import bunnyIconGraphics from '../assets/images/bunny-icon_graphics.png';
import bunnyIconWebdesign from '../assets/images/bunny-icon_webdesign.png';
import bunnyIconWebdev from '../assets/images/bunny-icon_webdev.png';

export default function Highlights () {

    return (
        <section className="highlights section-margin">
            <StylizedHeader
                headerClass="highlights__title"
                hType="2"
            >
                but what can I do for you? :)
            </StylizedHeader>

            <div className="highlights__cards">
                <CustomCard
                    title="graphics"
                    highlights={["Logo Design", "Emotes", "Other personal graphics"]}
                    image={bunnyIconGraphics}
                />
                <CustomCard
                    title="web design"
                    highlights={["Customized Websites", "Mockup Designs"]}
                    image={bunnyIconWebdesign}
                />
                <CustomCard
                    title="front-end web development"
                    highlights={["React.js", "Next.js"]}
                    image={bunnyIconWebdev}
                />
            </div>
        </section>
    );
}