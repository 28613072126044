import '../styles/components/icon-text-link.styles.scss';

//social media icons
import { ReactComponent as FacebookIcon } from '../assets/svg/facebook.svg';
import { ReactComponent as InstagramIcon } from '../assets/svg/instagram.svg';
import { ReactComponent as LinkedinIcon } from '../assets/svg/linkedin.svg';
import { ReactComponent as MailIcon } from '../assets/svg/mail.svg';
import { ReactComponent as PaypalIcon } from '../assets/svg/paypal.svg';
import { ReactComponent as PinterestIcon } from '../assets/svg/pinterest.svg';
import { ReactComponent as RedditIcon } from '../assets/svg/reddit.svg';
import { ReactComponent as TwitchIcon } from '../assets/svg/twitch.svg';
import { ReactComponent as TwitterIcon } from '../assets/svg/twitter.svg';
import { ReactComponent as YoutubeIcon } from '../assets/svg/youtube.svg';

export default function IconTextLink ({ icon, text, externalLink, internalLink }) {

    const socialIcon = {
        'facebook': FacebookIcon,
        'instagram': InstagramIcon,
        'linkedin': LinkedinIcon,
        'email': MailIcon,
        'paypal': PaypalIcon,
        'pinterest': PinterestIcon,
        'reddit': RedditIcon,
        'twitch': TwitchIcon,
        'twitter': TwitterIcon,
        'youtube': YoutubeIcon
    };

    const Icon = socialIcon[icon];

    /*

    {
                    link ?
                    (
                        <a href={link} className="icon-text-link--link">{ text }</a>
                    )
                    :
                    (
                        <Link
                            className="icon-text-link--link"
                            to={`/${internalLink === 'home' ? '' : internalLink}`}
                        >
                            { text }
                        </Link>
                     )
                }
    */

    return (
        <div className="icon-text-link">
            <div className="icon-text-link--icon">
                <Icon className="icon-text-link--svg" />
            </div>
            <div className="icon-text-link--text">
                <a href={externalLink} className="icon-text-link--link">{ text }</a>
            </div>
        </div>
    );
}