import StylizedHeader from './stylized-header.component';

import '../styles/components/similar-projects.styles.scss';

const SimilarProjects = () => (
    <section className="similar-projects section-margin">
        <StylizedHeader
            headerClass="similar-projects--title"
            hType="2"
        >
            similar projects
        </StylizedHeader>

        <div className="similar-projects__image-holder--wrapper">
            <div className="similar-projects__image-holder">
                <div className="similar-projects__image-holder__image-box">
                    <div className="similar-projects__image-holder__image-box--image">
                        image box
                    </div>
                </div>

                <div className="similar-projects__image-holder__image-box">
                    <div className="similar-projects__image-holder__image-box--image">
                        image box
                    </div>
                </div>

                <div className="similar-projects__image-holder__image-box">
                    <div className="similar-projects__image-holder__image-box--image">
                        image box
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default SimilarProjects;