import { Switch, Route } from 'react-router-dom';

import './styles/App.styles.scss';

import Home from './pages/home.pages';
import About from './pages/about.pages';
import Portfolio from './pages/portfolio.pages';
import Contact from './pages/contact.pages';
import PortfolioItem from './pages/portfolio-item.pages';

import Header from './components/header.component';
import Footer from './components/footer.comonent';

function App() {

  return (
    <div className="App">
      <Header />
      <main>
        <Switch>
          <Route path='/' component={Home} exact/>
          <Route path='/about' component={About} />
          <Route path='/portfolio' component={Portfolio} exact />
          <Route path='/contact' component={Contact} />
          <Route path='/portfolio/:itemId' component={PortfolioItem} />
        </Switch>
      </main>
      <Footer />
    </div>
  );
}

export default App;
