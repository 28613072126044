import '../styles/components/stylized-header.styles.scss';

export default function StylizedHeader ({ children, headerClass, hType, titleIsLogo }) { 
    switch(hType) {
        case '1' :
            return (
                <div className={`stylized-header stylized-header--1
                    ${headerClass ? headerClass : ''}
                    ${titleIsLogo ? 'stylized-header__logo' : ''}
                `}>
                    <div className={`stylized-header__top-text stylized-header--1__top-text
                        ${titleIsLogo ? 'stylized-header__logo__top-text' : ''}
                    `}>
                        { children }
                    </div>
                    <div className={`stylized-header__bottom-text stylized-header--1__bottom-text
                        ${titleIsLogo ? 'stylized-header__logo__bottom-text' : ''}
                    `}>
                        { children }
                    </div>
                    <h1 className="stylized-header__hidden stylized-header--1__hidden">{ children }</h1>
                </div>
            );
        case '2' :
            return (
                <div className={`stylized-header stylized-header--2
                    ${headerClass ? headerClass : ''}
                `}>
                    <div className="stylized-header__top-text stylized-header--2__top-text">{ children }</div>
                    <h2 className="stylized-header__hidden stylized-header--2__hidden">{ children }</h2>
                </div>
            );
        case '3' :
            return (
                <div className={`stylized-header stylized-header--3
                    ${headerClass ? headerClass : ''}
                `}>
                    <div className="stylized-header__top-text stylized-header--3__top-text">{ children }</div>
                    <h3 className="stylized-header__hidden stylized-header--3__hidden">{ children }</h3>
                </div>
            );
        default:
            return (
                <h1 className={`stylized-header
                    ${headerClass ? headerClass : ''}
                `}>
                    <div className="stylized-header__top-text">{ children }</div>
                    <div className="stylized-header__bottom-text">{ children }</div>
                    <div className="stylized-header__bg-fill">&nbsp;</div>
                </h1>
            );  
    }

}