import StylizedHeader from './stylized-header.component';

import '../styles/components/alt-heading.styles.scss';

const AltHeading = ({ title, subtitle, text }) => (
    <div className="alt-heading section-margin">
        <div className="alt-heading--wrapper wrapper-padding">
            <StylizedHeader
                headerClass="alt-heading__title"
                hType="1"
            >
                { title }
            </StylizedHeader>
            <h2 className="alt-heading__subtitle">{ subtitle }</h2>
            {
                text.map(paragraph => 
                    <p
                        className={`alt-heading__paragraphs__p
                                alt-heading__paragraphs__p--${text.indexOf(paragraph)}
                        `}
                        key={text.indexOf(paragraph)}
                    >
                        {paragraph}
                    </p>
                    
                )
            }
        </div>
    </div>
);

export default AltHeading;