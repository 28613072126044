import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import CustomButton from './custom-button.component';
import Popup from './pop-up.component';

import '../styles/components/gallery-builder.styles.scss';

export default function GalleryBuilder ({ images, filter, portfolioItemPage }) {
    const [galleryItem, setGalleryItem] = useState(images);
    const [popupContent, setPopupContent] = useState();

    const galleryGridRef = useRef(1);

    //this is currently not in use
    const handleFilter = (filterOption) => {
        //console.log(`filter gallery for ${filterOption}`);
    }

    const clearPopupContent = () => {
        setPopupContent(undefined);
    }

    const createPathname = (image) => {
        let pathName = image.replace(" ", "-");

        return pathName;
    }

    const renderGalleryGrids = () => {
        let grids = [];
        let currentGalleryItem = [];

        galleryItem.forEach((image) => {
            currentGalleryItem.push(image);

            if(currentGalleryItem.length === 4) {
                grids.push(
                    <div className="gallery-builder__grid"
                        key={`gallery-grid-${galleryGridRef.current}`}
                    >
                        {currentGalleryItem.map((image) => (
                            <Link to={{pathname: `/portfolio/${createPathname(image.title)}`, state: { portfolioItem: image } }}
                                className="gallery-builder__grid__box"
                                id={`gallery-grid-box__${image.id}`}
                                key={`gallery-grid-box-${image.id}`}
                                style={{
                                    backgroundImage: `url(images/portfolio-images/${image["additional-images"][0]["additional-image"]})`
                                }}
                            >
                            </Link>
                        ))}
                    </div>
                );
                galleryGridRef.current++;
                currentGalleryItem = [];
            }
        });

        if(currentGalleryItem.length > 0) {
            grids.push(
                <div className="gallery-builder__grid"
                    key={`gallery-grid-${galleryGridRef.current}`}
                >
                    {currentGalleryItem.map((image) => (
                        <Link to={{pathname: `/portfolio/${createPathname(image.title)}`, state: { portfolioItem: image } }}
                            className="gallery-builder__grid__box"
                            key={`gallery-grid-box-${image.id}`}
                            style={{
                                backgroundImage: `url(images/portfolio-images/${image["additional-images"][0]["additional-image"]})`
                            }}
                        >
                        </Link>
                    ))}
                </div>
            );
        }

        return grids;
    }

    const renderPortfolioItemGrids = () => {
        //if portfolioItemPage is true open larger image NOT portfolio-item page
        let grids = [];
        let currentGalleryItem = [];
        let portfolioImages = galleryItem["additional-images"];

        portfolioImages.forEach((image) => {
            currentGalleryItem.push(image);

            if(currentGalleryItem.length === 4) {
                grids.push(
                    <div className="gallery-builder__grid"
                        key={`gallery-grid-${galleryGridRef.current}`}
                    >
                        {currentGalleryItem.map((image) => (
                            <div
                                className="gallery-builder__grid__box"
                                id={`gallery-grid-box-portfolioitem__${image["image-alt"].replace(/\s/g, '-')}`}
                                key={`gallery-grid-box-portfolioitem-${image.id}`}
                                style={{
                                    backgroundImage: `url(/images/portfolio-images/${image["additional-image"]})`
                                }}
                                onClick={() => setPopupContent(image["additional-image"])}
                            >
                            </div>
                        ))}
                    </div>
                );
                galleryGridRef.current++;
                currentGalleryItem = [];
            }
        });

        if(currentGalleryItem.length > 0) {
            grids.push(
                <div className="gallery-builder__grid"
                    key={`gallery-grid-${galleryGridRef.current}`}
                >
                    {currentGalleryItem.map((image) => (
                        <div
                            className="gallery-builder__grid__box"
                            id={`gallery-grid-box-portfolioitem__${image["image-alt"].replace(/\s/g, '-')}`}
                            key={`gallery-grid-box-portfolioitem-${image.id}`}
                            style={{
                                backgroundImage: `url(/images/portfolio-images/${image["additional-image"]})`
                            }}
                            onClick={() => setPopupContent(image["additional-image"])}
                        >
                        </div>
                    ))}
                </div>
            );
        }

        return grids;
    }

    return(
        <div className="gallery-builder">
            {
                filter ?
                    <div className="gallery-builder__filter section-margin">
                        <span className="gallery-builder__filter--text">Filter:</span>
                        <div className="gallery-builder__filter--buttons">
                            {
                                filter.map((filterName) =>
                                    <CustomButton
                                        buttonClass={`gallery-builder__filter--button
                                                    gallery-builder__filter--button--${filter.indexOf(filterName)}
                                        `}
                                        key={`gallery-builder__filter--button--key${filter.indexOf(filterName)}`}
                                        handleEvent={e =>handleFilter(filterName)}
                                    >
                                        {filterName}
                                    </CustomButton>
                                )
                            }
                        </div>
                    </div>
                :
                    null
            }
            {
                portfolioItemPage ?
                    renderPortfolioItemGrids()
                :
                    renderGalleryGrids()

            }
            {
                portfolioItemPage ?
                    <Popup popupContent={popupContent} clearPopupContent={clearPopupContent} />
                :
                    null
            }

            {/*<div className="gallery-builder__grid-style-1 gallery-builder__grid--01">
                    <div className="
                        gallery-builder__grid__box
                        gallery-builder__grid__box--style-1
                        gallery-builder__grid-style-1--box-1
                        gallery-builder__grid__box--01"
                    >
                        box style 1
                    </div>

                    <div className="
                        gallery-builder__grid__box
                        gallery-builder__grid__box--style-2
                        gallery-builder__grid-style-1--box-2
                        gallery-builder__grid__box--02"
                    >
                        box style 2
                    </div>

                    <div className="
                        gallery-builder__grid__box
                        gallery-builder__grid__box--style-3
                        gallery-builder__grid-style-1--box-3
                        gallery-builder__grid__box--03"
                    >
                        box style 3
                    </div>

                    <div className="
                        gallery-builder__grid__box
                        gallery-builder__grid__box--style-3
                        gallery-builder__grid-style-1--box-4
                        gallery-builder__grid__box--04"
                    >
                        box style 3
                    </div>
                </div>

                <div className="gallery-builder__grid-style-2 gallery-builder__grid--02">
                    <div className="
                        gallery-builder__grid__box
                        gallery-builder__grid__box--style-1
                        gallery-builder__grid-style-2--box-1
                        gallery-builder__grid__box--01"
                    >
                        box style 1
                    </div>

                    <div className="
                        gallery-builder__grid__box
                        gallery-builder__grid__box--style-2
                        gallery-builder__grid-style-2--box-2
                        gallery-builder__grid__box--02"
                    >
                        box style 2
                    </div>

                    <div className="
                        gallery-builder__grid__box
                        gallery-builder__grid__box--style-3
                        gallery-builder__grid-style-2--box-3
                        gallery-builder__grid__box--03"
                    >
                        box style 3
                    </div>

                    <div className="
                        gallery-builder__grid__box
                        gallery-builder__grid__box--style-3
                        gallery-builder__grid-style-2--box-4
                        gallery-builder__grid__box--04"
                    >
                        box style 3
                    </div>
            </div>*/}
        </div>
    );
}