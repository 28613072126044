import { useEffect, useState } from 'react';
import StylizedHeader from './stylized-header.component';
import CustomButton from './custom-button.component';

import ViewportSize from './viewport-size.component';

import '../styles/components/hero.styles.scss';

export default function Hero ({ image, imageAlt, isBunnyImage, title, titleIsLogo, subtitle, text, button, buttonLink, heroClass, altHeroMobileStyle }) {

    const { width } = ViewportSize();
    const [isLogo, setIsLogo] = useState(false);
    const [mobileView, setMobileView] = useState(false);

    useEffect(()=> {
        if(titleIsLogo) {
            setIsLogo(true);
        }
    },[]);

    //watch mobilebreakpoint for altHeroMobileStyles
    useEffect(() => {
        const mobileBreakpoint = 600;
        width <= mobileBreakpoint ? setMobileView(true) : setMobileView(false);
    }, []);

    useEffect(() => {

        //const initialHeroSize = document.getElementsByClassName('hero__altMobileStyle')[0].offsetHeight;

        const setHeroHeight = () => {
            const initialHeroSize = document.getElementsByClassName('hero__altMobileStyle')[0].offsetHeight;

            const hero = document.getElementsByClassName('hero__altMobileStyle')[0];
            const detailsHeight = document.getElementsByClassName('hero--info-wrapper__altMobileStyle')[0].offsetHeight;
            const newHeroHeight = initialHeroSize.offsetHeight + detailsHeight;

            //console.log(`hero height: ${hero.offsetHeight}`);
            //console.log(`detail height: ${detailsHeight}`);
            //console.log(`added hero height: ${newHeroHeight}`);


            hero.style.height = `${newHeroHeight}px`;
        };

        if(mobileView && altHeroMobileStyle) {
            setHeroHeight();
        }

    },[mobileView]);

    return (
        <section className={`hero section-margin translucent-bg
            ${ heroClass ? heroClass : ''}
            ${ altHeroMobileStyle ? 'hero__altMobileStyle' : ''}
        `}>
            <div className={`hero--wrapper wrapper-padding
                ${altHeroMobileStyle ? 'hero--wrapper__altMobileStyle' : ''}
            `}>

                <div className={`hero__side hero__side--01
                    ${altHeroMobileStyle ? 'hero__side__altMobileStyle hero__side--01__altMobileStyle' : ''}
                `}>
                    <div className={`hero__image--wrapper
                        ${isBunnyImage ? 'hero__image__bunny-image--wrapper' : ''}
                        ${altHeroMobileStyle ? 'hero__image__altMobileStyle--wrapper' : ''}
                    `}>
                        {
                            isBunnyImage ?
                            (
                                <img src={image} alt={imageAlt}
                                    className={`hero__image
                                        ${isBunnyImage ? 'hero__image__bunny-image' : ''}
                                        ${altHeroMobileStyle ? 'hero__image__altMobileStyle' : ''}
                                    `}
                                />
                            )
                            :
                            (
                                <div
                                    className={`hero__image
                                        ${isBunnyImage ? 'hero__image__bunny-image' : ''}
                                        ${altHeroMobileStyle ? 'hero__image__altMobileStyle' : ''}
                                    `}
                                    style={{
                                        backgroundImage: `url(${image})`
                                    }}
                                >
                                </div>
                            )

                        }
                    </div>
                </div>

                <div className={`hero__side hero__side--02
                    ${altHeroMobileStyle ? 'hero__side__altMobileStyle hero__side--02__altMobileStyle' : ''}
                `}>
                    <StylizedHeader
                        headerClass="hero__title"
                        hType="1"
                        titleIsLogo={isLogo}
                    >
                        {title}
                    </StylizedHeader>
                    <div className={`hero--info-wrapper
                        ${altHeroMobileStyle ? 'hero--info-wrapper__altMobileStyle' : ''}
                    `}>

                        <h2 className={`hero__subtitle
                            ${altHeroMobileStyle ? 'hero__subtitle__altMobileStyle' : ''}
                        `}>
                            {subtitle}
                        </h2>
                        <div className="hero__paragraphs">
                            {
                                text.map(paragraph =>
                                    <p
                                        className={`hero__paragraphs__p
                                                hero__paragraphs__p--${text.indexOf(paragraph)}
                                        `}
                                        key={text.indexOf(paragraph)}
                                    >
                                        {paragraph}
                                    </p>

                                )
                            }
                        </div>

                        {
                            button ?
                            <CustomButton
                                linkToPage={ buttonLink ? buttonLink : false }
                            >
                                { button }
                            </CustomButton>
                            :
                            null
                        }

                    </div>


                </div>

            </div>
        </section>
    );
}