import { useLocation } from 'react-router-dom';

import Hero from '../components/hero.component';
import SimilarProjects from '../components/similar-projects.component';
import StylizedHeader from '../components/stylized-header.component';
import GalleryBuilder from '../components/gallery-builder.component';

import '../styles/pages/portfolio-item.styles.scss';

import animateImage from '../assets/images/tools/adobe_animate.png';
import illustratorImage from '../assets/images/tools/adobe_illustrator.png';
import photoshopImage from '../assets/images/tools/adobe_photoshop.png';
import htmlImage from '../assets/images/tools/html5.png';
import javascriptImage from '../assets/images/tools/javascript.png';
import scssImage from '../assets/images/tools/sass-scss.png';
import reactjsImage from '../assets/images/tools/reactjs.png';
import vitejsImage from '../assets/images/tools/vitejs.png';
import nextjsImage from '../assets/images/tools/nextjs.png';

export default function PortfolioItem () {
    const location = useLocation();
    const { portfolioItem } = location.state;

    const toolImages = {
        animate: animateImage,
        illustrator: illustratorImage,
        photoshop: photoshopImage,
        html: htmlImage,
        javascript: javascriptImage,
        scss: scssImage,
        reactjs: reactjsImage,
        vitejs: vitejsImage,
        nextjs: nextjsImage
    };

    const renderPortfolioTools = () => {
        let importedTools = portfolioItem.tools;
        let displayTools = [];

        importedTools.map((tool) => {
            displayTools.push(
                <div className="portfolio-item__tool-bar--tool">
                    <div className="portfolio-item__tool-bar--tooltip">
                        <div className="portfolio-item__tool-bar--tooltiptext">
                            {tool}
                        </div>
                        <img className="portfolio-item__tool-bar--tool-img"
                            src={toolImages[tool]} alt={tool} />
                    </div>
                </div>
            );
        });

        return displayTools;
    }

    const renderSubtitle = () => {
        let isLink = portfolioItem.subtitle["is-link"];
        let subtitle = portfolioItem.subtitle.subtitle;

        if(isLink) {
            return(
                <a href={subtitle}>{subtitle}</a>
            );
        } else {
            return subtitle;
        }
    }

    return (
        <div className="portfolio-item">
            <Hero
                image={`/images/portfolio-images/${portfolioItem["additional-images"][0]["additional-image"]}`}
                title={portfolioItem.title}
                altHeroMobileStyle
                subtitle={renderSubtitle()}
                text={portfolioItem.overview}
            />

            <div className="portfolio-item__tool-bar">
                <span className="portfolio-item__tool-bar--title">Project Tools:</span>
                {renderPortfolioTools()}
            </div>

            <section className="portfolio-item__process section-margin">
                <StylizedHeader
                    headerClass="portfolio-item__process--title"
                    hType="3"
                >
                    more images
                </StylizedHeader>

                <GalleryBuilder portfolioItemPage images={portfolioItem} />

                {/*<section className="portfolio-item__process__part portfolio-item__process__part--01">
                    <div className="portfolio-item__process__part--image portfolio-item__process__part--side portfolio-item__process__part--side--01">
                        image box
                    </div>

                    <div className="portfolio-item__process__part--text portfolio-item__process__part--side portfolio-item__process__part--side--02">
                        <h3 className="portfolio-item__process__part--title">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                        </h3>
                        <p className="portfolio-item__process__part--p">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem reprehenderit ratione officiis aliquam repellendus? Porro, distinctio pariatur? Reprehenderit earum ad officia ratione quaerat perferendis libero veniam praesentium maiores nesciunt. Alias.
                        </p>
                    </div>
                </section>

                <section className="portfolio-item__process__part portfolio-item__process__part--02">
                    <div className="portfolio-item__process__part--image portfolio-item__process__part--side portfolio-item__process__part--side--01">
                        image box
                    </div>

                    <div className="portfolio-item__process__part--text portfolio-item__process__part--side portfolio-item__process__part--side--02">
                        <h3 className="portfolio-item__process__part--title">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                        </h3>
                        <p className="portfolio-item__process__part--p">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem reprehenderit ratione officiis aliquam repellendus? Porro, distinctio pariatur? Reprehenderit earum ad officia ratione quaerat perferendis libero veniam praesentium maiores nesciunt. Alias.
                        </p>
                    </div>
            </section>*/}
            </section>

            {/*<SimilarProjects />*/}
        </div>
    );
}