import '../styles/components/logo.styles.scss';

import { ReactComponent as SmallLogo } from '../assets/svg/victoria-jones-design_small-logo.svg';

const Logo = ({ logoClass }) => (
    <div className={`logo
        ${logoClass ? 'logo__' + logoClass : ''}
    `}>
        <SmallLogo
            className="logo__svg"
        />
    </div>
);

export default Logo;